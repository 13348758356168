import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import logo from '../../assets/sk_headerlogo.png';

import './styles.css';

export default class extends Component {


  render() {
    return (
      <Container >
        <Row className='support_title_container'>
          <Col className='support_logo_container'>
            <Link to='/'>
              <img src={logo} className='support_logo'
                alt='allSaskatchewan-logo'/>
            </Link>
          </Col>
          <Col xl='4' lg='3' md='4' sm='4' xs='4' className='support_title'>
            <div className='support_support_title'>SUPPORT</div>
          </Col>
        </Row>

        <Row className='support_content_container'>
          <Col xs lg='10' className='support_content'>
            <h3>I don&#39;t know my password or my password is not working.</h3>
            <p>Our system can help you retrieve or reset your password.</p>
            <p>
              <strong>Step 1:</strong> Click on the Forgot Password key on our website.<br/>
              <strong>Step 2:</strong> Enter the email address where you receive the allSaskatchewan nightly headlines.<br/>
              <strong>Step 3:</strong> Go to your email, open an email from allSaskatchewan.com and fill in a new password. Note this must be a new password at least six characters long.<br/>
              <strong>Step 4:</strong> Use this new password to log-on.<br/>
            </p>
            <p>Or call 1-877-240-4130 for help.</p>

            <h3>I have some other problem!</h3>
            <p>
              Please call the office (1-877-240-4130) and we&#39;ll work with you to solve it.
            </p>
          </Col>
        </Row>

      </Container>
    );
  }
}