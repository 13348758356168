import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import logo from '../../assets/sk_headerlogo.png';

import './styles.css';

export default class extends Component {


  render() {
    return (
      <Container>
        <Row className='privacy_title_container'>
          <Col className='privacy_logo_container'>
            <Link to='/'>
              <img src={logo} className='privacy_logo'
                alt='allSaskatchewan-logo'/>
            </Link>
          </Col>
          <Col xl='6' lg='7' md='6' sm='6' xs='6' className='privacy_title'>
            <div className='privacy_privacy_title'>PRIVACY POLICY</div>
          </Col>
        </Row>

        <Row className='privacy_content_container'>
          <Col xs lg='10' className='privacy_content'>
            <p>
              allSaskatchewan understands the importance of respecting and
              protecting reader information. The company collects limited
              personal data submitted by the reader for the sole purpose of
              providing information published in allSaskatchewan
            </p>
            <p>
              Other than for select business operations,
              private information is never shared with any other parties.
            </p>
            <p>
              No readership data is ever collected internally, with the
              exception of screenshot captures which are flagged for copyright
              content protection.
              Device information is collected on occasion to ensure effective
              and efficient development.
            </p>

            <p>President Caroline Wood is the company privacy officer.</p>
          </Col>
        </Row>

      </Container>
    );
  }
}
